import { LitElement, html } from 'lit';
import { TimedExerciseQuestion, TimedExerciseQuestionService, TimedExerciseResults } from '../models/timed-exercise';
import { CellColorTimedExerciseQuestionService } from '../timed-exercise/cell-color-exercise';
import { ClientService } from '../client-service/client-service';
import { RowColDistanceTimedExerciseQuestionService } from '../timed-exercise/row-col-distance-exercise';
import { customElement } from 'lit/decorators';

enum STATE {
    startScreen = "start-screen",
    puzzleQuestion = "puzzle-question",
    puzzleLastResult = "puzzle-last-result",
    resultsScreen = "results-screen"
}

// Define the abstract base class
@customElement('timed-exercise')
export class TimedExerciseComponent extends LitElement {

    private DEFAULT_SESSION_DURATION = 1_000*60;

    private results: TimedExerciseResults = {
        numChallengesCorrect: 0,
        numChallengesIncorrect: 0,
        numChallengesTotal: 0
    };
    private lastCorrect = false;
    private state: STATE = STATE.startScreen;

    private clientService = new ClientService();

    private gameTypesEnabled = [
        new CellColorTimedExerciseQuestionService(),
        new RowColDistanceTimedExerciseQuestionService()
    ];

    private currentGameDuration!: number;
    private currentGameType!: TimedExerciseQuestionService;
    private gameOverTimeout: any;

    // Override the render method to call the abstract doRender method
    protected render() {
        let innerState = html``;
        switch (this.state) {
            case STATE.startScreen:
                innerState = this.renderStartScreen();
                break;
            case STATE.puzzleQuestion:
                innerState = this.renderPuzzleQuestion();
                break;
            case STATE.puzzleLastResult:
                innerState = this.renderPuzzleLastResult();
                break;
            case STATE.resultsScreen:
                innerState = this.renderPuzzleGameOver();
                break;
        }

        return innerState;
    }

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    start(): void {
        this.clearResults();
    }

    private clearResults(): void {
        this.results = {
            numChallengesCorrect: 0,
            numChallengesIncorrect: 0,
            numChallengesTotal: 0
        };
    }

    private onCorrectResultSubmitted(): void {
        this.results.numChallengesCorrect ++;
        this.lastCorrect = true;
        this.onResultSubmitted();
    }

    private onIncorrectResultSubmitted(): void {
        this.results.numChallengesIncorrect ++;
        this.lastCorrect = false;
        this.onResultSubmitted();
    }

    private onResultSubmitted(): void {
        this.state = STATE.puzzleLastResult;
        this.results.numChallengesTotal ++;
        this.requestUpdate();
        setTimeout(() => {
            this.state = STATE.puzzleQuestion;
            this.requestUpdate();
        }, 500);
    }

    private renderStartScreen() {
        return html`
            <style>
                #exercises-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1em;
                    justify-content: center;
                }

                .exercise {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    width: 20em;
                    border: 1px solid var(--body-color);
                    border-radius: 10px;
                    padding: 1em;
                }

                .exercise:hover {
                    cursor: pointer;
                    background-color: var(--button-hover-background-color, #3A3A3A); /* Hover background color */
                    color: var(--background-color, #2D2D2D);
                }
            </style>
            <h2>Select an Exercise</h2>
            <div id="exercises-container">
            ${this.gameTypesEnabled.map(type => {
                return html`
                    <div class="exercise" @click="${() => {
                        this.startSession(this.DEFAULT_SESSION_DURATION, type);
                    }}">
                        <h3>${type.getInfo().title}</h3>
                        <p>${type.getInfo().description}</p>
                    </div>
                `
            })}
            </div>
            <hr/>
            <styled-button @click="${() => {
                this.dispatchExit();
            }}">Go Back</styled-button>
        `;
    }

    private startSession(
        timeMs: number,
        questionService: TimedExerciseQuestionService
    ) {
        this.currentGameDuration = timeMs;
        this.currentGameType = questionService;

        // TODO: this needs to survive a refresh
        this.gameOverTimeout = setTimeout(() => {
            this.onGameExpired();
        }, timeMs);

        this.state = STATE.puzzleQuestion;
        this.requestUpdate();
    }

    private renderPuzzleQuestion() {
        const question = this.currentGameType.getQuestion();

        return html`
            <h2>${this.currentGameType.getInfo().title}</h2>
            ${this.renderPuzzleChoices(question)}
        `;
    }

    private renderPuzzleLastResult() {
        return html`
            <h2>${this.currentGameType.getInfo().title}</h2>
            <p>${this.lastCorrect ? "Correct" : "Incorrect"}</p>
        `;
    }

    private renderPuzzleGameOver() {
        return html`
            <style>
                #exercise-summary {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                }
            </style>
            <div id="exercise-summary">
                <h2>Time's Up!</h2>
                <p style="margin-bottom: 0">
                    You've completed <b>${this.results.numChallengesTotal}</b> questions with:
                </p>
                <ul>
                    <li><b>${this.results.numChallengesCorrect}</b> correct answer${this.results.numChallengesCorrect === 1 ? "" : "s"}.
                    <li><b>${this.results.numChallengesIncorrect}</b> incorrect answers${this.results.numChallengesIncorrect === 1 ? "" : "s"}.
                </ul>
                <div>
                    <styled-button @click="${() => {
                        this.startSession(this.currentGameDuration, this.currentGameType);
                    }}">Play Again</styled-button>
                    <styled-button @click="${() => {
                        this.state = STATE.startScreen;
                        this.requestUpdate();
                    }}">Go Back</styled-button>
                </div>
            </div>
        `;
    }

    private onGameExpired() {
        this.state = STATE.resultsScreen;
        this.requestUpdate();
    }

    protected renderPuzzleChoices({prompt, choices}: TimedExerciseQuestion): any {
        return html`
            <style>
                #prompt-choices {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0.5em;
                    justify-content: center;
                    gap: 0.5em;
                }
            </style>
            ${prompt}
            <br/><br/>
            <div id="prompt-choices">
                ${choices.map(choice => {
                    return html`
                    <styled-button @click="${() => {
                        if (choice.isCorrect) {
                            this.onCorrectResultSubmitted();
                        } else {
                            this.onIncorrectResultSubmitted();
                        }
                    }}">${choice.text}</styled-button>
                    `
                })}
            </div>
            <hr/>
            <styled-button @click="${() => {
                clearTimeout(this.gameOverTimeout);
                this.onGameExpired();
            }}">Stop</styled-button>
        `;
    }

    private dispatchExit() {
        this.dispatchEvent(new CustomEvent('exit'));
    }
}
