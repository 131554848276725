import { Client, ClientConfig } from "../models/client-config";
import { ANDROID_APP_CONFIG } from "./android-app-config";
import { WEB_BROWSER_CONFIG } from "./web-browser-config";
import BID from "./b.json";
import { LOCALHOST_CONFIG } from "./localhost-app-config";
import { ANDROID_SMARTWATCH_APP_CONFIG } from "./android-smartwatch-app-config";

export class ClientService {

    // hacky singleton implementation
    private static instance: ClientService;

    private client!: Client;
    private config!: ClientConfig;

    constructor() {
        if (ClientService.instance) {
            return ClientService.instance;
        }

        const clientType = new URLSearchParams(window.location.search)
            .get("c") || null;

        switch (clientType) {
            case Client.androidApp:
                this.client = Client.androidApp;
                this.config = ANDROID_APP_CONFIG;
                break;
            case Client.androidSmartWatch:
                this.client = Client.androidSmartWatch;
                this.config = ANDROID_SMARTWATCH_APP_CONFIG;
                break;
            default:
                if (window.location.hostname.includes(".com")) {
                    this.client = Client.webBrowser;
                    this.config = WEB_BROWSER_CONFIG;
                } else {
                    this.client = Client.localhost;
                    this.config = LOCALHOST_CONFIG;
                }
                
                break;
        }

        ClientService.instance = this;
        console.log("[ClientService] client is " + this.client);
        this.interceptAppHref();
        this.updateBodyStyles();
    }

    getConfig(): ClientConfig {
        // hacky way to prevent mutation related issues
        // TODO: lock / freeze this object instead
        return JSON.parse(JSON.stringify(this.config));
    }

    setIsDailyContext(doHighlight: boolean): void {
        console.log("[ClientService] showing or hiding daily highlight", doHighlight);

        const dailyEl = document.querySelector("#daily-menu");
        const url = new URL(window.location.href);

        if (doHighlight) {
            dailyEl?.classList.add("daily-puzzle-active");
            url.searchParams.set("d", "daily");
        } else {
            dailyEl?.classList.remove("daily-puzzle-active");
            url.searchParams.delete("d");
        }

        window.history.replaceState({}, '', url.toString());
    }

    createLocalLink(path: string, queryParams: { [key: string]: string }, includeClient: boolean): string {
        if (path.substring(0) !== "/") {
            path = "/" + path;
        }

        let protocol = window.location.protocol;
        let host = window.location.host;
        if (host.indexOf("localhost") > -1) {
            host = "playblindfoldchess.com";
            protocol = "https://";
        }

        const currentUrlWithBParam = new URL(
            protocol + (host + path).replace(/playblindfoldchess\.com\/+/, "playblindfoldchess.com/")
        );

        for (const key in queryParams) {
            currentUrlWithBParam.searchParams.set(key, queryParams[key]);
        }

        if (includeClient && this.client !== Client.webBrowser) {
            currentUrlWithBParam.searchParams.set('c', this.client);
        } else {
            currentUrlWithBParam.searchParams.delete('c');
        }

        return currentUrlWithBParam.toString();
    }

    /**
     * Add client query param
     * @returns 
     */
    private interceptAppHref(): void {
        document.body.addEventListener('click', (event: MouseEvent) => {
            const target = event.target as HTMLAnchorElement;

            // Check if the clicked element is an anchor and it has a href attribute
            if (target.tagName === 'A' && target.href) {
                event.preventDefault();  // Stop the default navigation

                const hrefUrl = new URL(target.href);
                const currentDomain = window.location.hostname;

                // Append a query parameter
                if (hrefUrl.hostname === currentDomain && this.client !== Client.webBrowser) {
                    hrefUrl.searchParams.append('c', this.client);
                }

                let url = hrefUrl.toString();

                console.log("[ClientService] opening url", url);

                // Conditional navigation based on the target attribute
                if (target.target === '_blank') {
                    // Open in a new tab if specified
                    window.open(url, '_blank');
                } else {
                    // Navigate in the same tab
                    window.location.href = url;
                }
            }
        }, true);
    }

    private updateBodyStyles(): void {
        // append body styles
        console.log("[ClientService] appending body classes", this.config.body.cssClasses);
        for (const cssClass of this.config.body.cssClasses) {
            document.body.classList.add(cssClass);
        }

        // update menu css to show current page
        document.addEventListener("DOMContentLoaded", () => {
            const currentPath = window.location.pathname.replace("/", "") || "app";
            const anchorElements: any[] = [...document.querySelectorAll("a")!];
            for (const link of anchorElements) {
                if (link.hostname !== window.location.hostname) {
                    continue;
                }

                const hrefPath = new URL(link.href).pathname.replace("/", "");
                if (link.classList.contains("menu-href") && currentPath === hrefPath) {
                    link.classList.add("current-page");
                }
            }
        });
    }
}