import { Emitter } from 'mitt';
import { PlayerMove, PlayerType } from '../models/chess-engine-models';

// Ensure MoveEvent is just an alias for PlayerMove, if they're meant to be the same
type MoveEvent = PlayerMove;

export class HumanPlayer {
    type: PlayerType = PlayerType.Human;
    private eventEmitter: Emitter<{ move: PlayerMove }>; // Explicitly define the event types

    constructor(eventEmitter: Emitter<{ move: PlayerMove }>) {
        this.eventEmitter = eventEmitter;
    }

    // Get the move from the human player via the event emitter, only listen for one move
    public async getMove(): Promise<PlayerMove> {
        return new Promise((resolve) => {
            const handler = (move: PlayerMove) => {
                console.log(`[HumanPlayer] move made`, move);
                this.eventEmitter.off('move', handler); // Remove the listener after the first event
                resolve(move);
            };

            // Listen for the 'move' event and resolve with the PlayerMove, then remove listener
            this.eventEmitter.on('move', handler);
        });
    }
}
