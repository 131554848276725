import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import mitt, { Emitter } from 'mitt';
import { ChessGame } from '../chess-engine/chess-game';
import { AIPlayer } from '../chess-engine/ai-player';
import { HumanPlayer } from '../chess-engine/human-player';
import { PlayerMove, PlayerType } from '../models/chess-engine-models';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { StorageService } from '../models/storage-service';
import { ChessGameSession, GameSummary } from '../models/chess-game-session';
import { Retry } from '../retry/retry';
import { ModalService } from '../modal-service/modal-service';
import { ClientService } from '../client-service/client-service';
import { AxisOrientation } from '../models/client-config';
import { TEXT_ABOUT } from '../texts/text-about';
import { TEXT_MORE } from '../texts/text-more';
import { SoundService } from '../sound-service/sound-service';
import { Sounds } from '../sound-service/sounds';

enum GameState {
    initializing = "initializing",
    gamePending = "game-pending",
    playerTurn = "player-turn",
    aiTurn = "ai-turn",
    gameOver = "game-over",
    confirmResign = "confirm-resign",
    displayInvalidMove = "display-invalid-move",
    displayAbout = "display-about",
    displayMore = "display-more",
    viewGame = "view-game",
    timedExercise = "timed-exercise"
}

interface GameOverState {
    playerResigned: boolean;
    isStalemate: boolean;
    winner: PlayerType | null;
}

enum PlayerSelection {
    white = "white",
    black = "black",
    random = "random"
};

@customElement('blind-chess-game')
class BlindChessGame extends LitElement {

    private static appStyles = css`
        #app-container {
            position: relative;
            transform: translate(-50%, -70%);
            left: 50%;
            top: 50%;
        }

        #centered-container {
            position: relative;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }

        #content-container {
            width: 65%;
            position: relative;
            transform: translateX(-50%);
            left: 50%;
        }

        .post-game-action {
            width: 10em;
        }

        @media screen and (min-width: 1500px) {
            #app-container {
                width: 50%;
            }

            #centered-container {
                width: 50%;
            }
        }

        @media screen and (max-width: 1000px) {
            #content-container {
                width: 90%;
            }
        }
    `;

    // static
    private static RESIGN_BUTTON = {
        code: 'resign',
        text: 'Resign'
    };
    private static HOW_TO_BUTTON = {
        code: 'how-to',
        text: 'Help'
    };
    private static AI_DIFFICULTY_LEVELS = {
        easy: 0,
        medium: 5,
        difficult: 13,
        //supreme: 20
    }

    private static MAX_INVALID_MOVES = 5;

    // dependencies
    private storageService: StorageService = new LocalStorageService();
    private modalService: ModalService = new ModalService();
    private clientService: ClientService = new ClientService();
    private soundService = new SoundService();

    // chess game state
    private sessionCache!: ChessGameSession;
    private gameState = GameState.initializing;
    private chessGame!: ChessGame;
    private playerMoveEventEmitter = mitt<{ move: PlayerMove }>();
    private lastMove!: string;
    private saveGameKey = "chess-game";
    private gameOverState!: GameOverState;
    private displayPriorGameState?: GameState;
    private difficulty = -1;
    private playerSelection!: PlayerSelection;

    // state transition
    private isTransitioning = false;


    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    render() {
        const elements: any[] = [
            html`<style>${BlindChessGame.appStyles}</style>`
        ];
        console.log(`[BlindChessGame] rendering, session cache:`, this.sessionCache)

        switch (this.gameState) {
            case GameState.gamePending:
                elements.push(html`<div id="app-container">${this.renderGamePending()}</div>`);
                break;
            case GameState.playerTurn:
                elements.push(html`<div id="app-container">${this.renderPlayerMove()}</div>`);
                break;
            case GameState.aiTurn:
                elements.push(html`<div id="app-container">${this.renderAiMove()}</div>`);
                break;
            case GameState.gameOver:
                elements.push(html`<div id="app-container">${this.renderGameOver()}</div>`);
                break;
            case GameState.confirmResign:
                elements.push(html`<div id="app-container">${this.renderConfirmResign()}</div>`);
                break;
            case GameState.displayInvalidMove:
                elements.push(html`<div id="app-container">${this.renderDisplayInvalidMove()}</div>`);
                break;
            case GameState.displayAbout:
                elements.push(html`<div id="content-container">${this.renderDisplayAbout()}</div>`);
                break;
            case GameState.displayMore:
                elements.push(html`<div id="content-container">${this.renderDisplayMore()}</div>`);
                break;
            case GameState.viewGame:
                elements.push(html`<div id="centered-container">${this.renderViewGame()}</div>`);
                break;
            case GameState.timedExercise:
                elements.push(html`<div id="app-container">${this.renderTimedExercise()}</div>`);
                break;
        }

        return elements;
    }

    async connectedCallback(): Promise<void> {
        super.connectedCallback();
        const gameLoaded = await this.tryLoadGame();
        if (!gameLoaded) {
            this.gameState = GameState.gamePending;
            this.requestUpdate();
        }

        window.addEventListener('navigate-about', () => {
            if (!this.displayPriorGameState) {
                this.displayPriorGameState = this.gameState;
            }
            this.gameState = GameState.displayAbout;
            this.requestUpdate();
        });

        window.addEventListener('navigate-app', () => {
            if (this.displayPriorGameState) {
                this.gameState = this.displayPriorGameState!;
                this.displayPriorGameState = undefined;
                this.requestUpdate();
            }
        });

        window.addEventListener('navigate-more', () => {
            if (!this.displayPriorGameState) {
                this.displayPriorGameState = this.gameState;
            }
            this.gameState = GameState.displayMore;
            this.requestUpdate();
        });
    }

    private renderGamePending() {
        let puzzlesTrainingTemplate = html``;
        const configTraining = this.clientService.getConfig().training;
        if (configTraining.enabled) {
            const puzzleTemplate = !configTraining.puzzlesEnabled ? "" : html`
                <styled-button>Puzzles</styled-button>
            `;
            const trainingTemplate = !configTraining.puzzlesEnabled ? "" : html`
                <styled-button @click="${() => {
                    this.gameState = GameState.timedExercise;
                    this.requestUpdate();
                }}">Exercises</styled-button>
            `;

            puzzlesTrainingTemplate = html`
                <style>
                    #options-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 0.5em;
                    }
                </style>
                <split-hr>or</split-hr>
                <div id="options-container">
                    ${puzzleTemplate}
                    ${trainingTemplate}
                </div>
            `
        }

        return html`
            <style>
                .piece-select {
                    height: calc(var(--item-size) * 4);
                    gap: 0.5em;
                }

                .difficulty-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 0.75em;
                }

                .options-columns {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    width: fit-content;
                    gap: 1em;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .piece-text {
                    margin-bottom: 0;
                }

                .invert-on-hover:hover {
                    filter: invert(1);
                }
            </style>
            <h2>New Game</h2>
            <div class="options-columns">
                <div>
                    <styled-button ?selected="${this.playerSelection === PlayerSelection.white}" @click="${() => {
                        this.playerSelection = PlayerSelection.white;
                        this.requestUpdate(void 0, void 0, {});
                    }}">
                        <img style="${this.playerSelection === PlayerSelection.white ? "filter: invert(1)" : ""}" src="knight-light.svg" class="piece-select invert-on-hover" />
                        <p class="piece-text">White</p>
                    </styled-button>
                    <br>
                    <br>
                    <styled-button ?selected="${this.difficulty === BlindChessGame.AI_DIFFICULTY_LEVELS.easy}" @click="${() => {
                        this.difficulty = BlindChessGame.AI_DIFFICULTY_LEVELS.easy;
                        this.requestUpdate(void 0, void 0, {});
                    }}">Lower Elo</styled-button>
                </div>
                <div>
                    <styled-button ?selected="${this.playerSelection === PlayerSelection.random}" @click="${() => {
                        this.playerSelection = PlayerSelection.random;
                        this.requestUpdate(void 0, void 0, {});
                    }}">
                        <img style="${this.playerSelection === PlayerSelection.random ? "filter: invert(1)" : ""}" src="question-random.svg" class="piece-select invert-on-hover" />
                        <p class="piece-text">Random</p>
                    </styled-button>
                    <br>
                    <br>
                    <styled-button ?selected="${this.difficulty === BlindChessGame.AI_DIFFICULTY_LEVELS.medium}" @click="${() => {
                        this.difficulty = BlindChessGame.AI_DIFFICULTY_LEVELS.medium;
                        this.requestUpdate(void 0, void 0, {});
                    }}">Medium</styled-button>
                </div>
                <div>
                    <styled-button ?selected="${this.playerSelection === PlayerSelection.black}" @click="${() => {
                        this.playerSelection = PlayerSelection.black;
                        this.requestUpdate(void 0, void 0, {});
                    }}">
                        <img style="${this.playerSelection === PlayerSelection.black ? "filter: invert(1)" : ""}" src="knight-dark.svg" class="piece-select invert-on-hover" />
                        <p class="piece-text">Black</p>
                    </styled-button>
                    <br>
                    <br>
                    <styled-button ?selected="${this.difficulty === BlindChessGame.AI_DIFFICULTY_LEVELS.difficult}" @click="${() => {
                        this.difficulty = BlindChessGame.AI_DIFFICULTY_LEVELS.difficult;
                        this.requestUpdate(void 0, void 0, {});
                    }}">Higher Elo</styled-button>
                </div>
            </div>
            <br/><br/>
            <styled-button @click="${() => this.startNewGame()}">
                Start Game!
            </styled-button>
            <styled-button @click="${() => this.onHowToClick()}">
                How to Play
            </styled-button>
            ${puzzlesTrainingTemplate}
        `
    }

    private renderPlayerMove() {
        const isHorizontal = this.clientService.getConfig().chessInput.orientation
            === AxisOrientation.horizontal;

        const elements: any[] = [];
        elements.push(html`
            ${this.lastMove ? html`<p class="last-move">Last move: <b>${this.lastMove}</b></p><hr/>` : ''}
            <h2>Your Move</h2>
            <player-move-input-form
                .horizontal="${isHorizontal}"
                .isWhite="${this.sessionCache.playerOne === PlayerType.Human}"
                @move-submit="${(e: CustomEvent) => this.onPlayerMoveSubmit(e)}"
                .actionButtons="${[
                    BlindChessGame.HOW_TO_BUTTON,
                    BlindChessGame.RESIGN_BUTTON
                ]}"
                @action-button-click-resign="${() => this.setStateConfirmResign()}"
                @action-button-click-how-to="${() => this.onHowToClick()}"
            ></player-move-input-form>
        `);
        return elements;
    }

    private renderAiMove() {
        return html`
            <style>
                .last-move {
                    font-size: 90%;
                    color: #A0A0A0; /* Subtle gray */
                }

                .ai-move-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 1em;
                }
            </style>
            ${this.lastMove ? html`<p class="last-move">Your last move: <b>${this.lastMove}</b></p><hr/>` : ''}
            <p>
                Please wait while the AI makes its move.
                <br/>
                The AI is playing with ${this.sessionCache.playerOne === PlayerType.AI
                ? 'light'
                : 'dark'
            } pieces.
            </p>
            <div class="ai-move-buttons">
                <styled-button @click="${() => this.setStateConfirmResign()}">${BlindChessGame.RESIGN_BUTTON.text}</styled-button>
            </div>
        `
    }

    private renderGameOver() {
        let endGameType = "";
        let playerWinMessage: any = "";
        let numMoves = Math.ceil(this.sessionCache.numPlies / 2);
        let numMovesText = `${numMoves} move${numMoves !== 1 ? "s" : ""}.`;
        if (this.gameOverState.isStalemate) {
            endGameType = "Stalemate!";
            playerWinMessage = "The game lasted for " + numMovesText;
        } else if (this.gameOverState.playerResigned) {
            if (this.sessionCache.numInvalidMoves === BlindChessGame.MAX_INVALID_MOVES) {
                endGameType = "Player Lost Hold of the Board!"
                playerWinMessage = `The game automatically ended because you made ${this.sessionCache.numInvalidMoves} invalid moves this game. The AI won in ${numMovesText}`;
            } else {
                endGameType = "Player Resigned!"
                playerWinMessage = "The AI won the game in " + numMovesText;
            }
        } else {
            endGameType = "Checkmate!"
            playerWinMessage = this.gameOverState.winner === PlayerType.AI
                ? html`The AI won the game with <span class="last-move">${this.lastMove}</span> in ${numMovesText}`
                : "You won the game in " + numMovesText;
        }

        return html`
            <h2>${endGameType}</h2>
            ${playerWinMessage ? html`<p>${playerWinMessage}` : ``}
            <styled-button class="post-game-action" @click="${(e: any) => {
                this.onCopyMoves(e);
            }}">Copy Moves</styled-button>
            <styled-button class="post-game-action" @click="${() => {
                this.onViewGame();
            }}">View Game</styled-button>
            <styled-button class="post-game-action" @click="${() => {
                this.gameState = GameState.gamePending;
                this.requestUpdate();
            }}">Play Again</styled-button>
        `;
    }

    private setStateConfirmResign() {
        if (!this.displayPriorGameState) {
            this.displayPriorGameState = this.gameState;
        }
        this.gameState = GameState.confirmResign;

        this.requestUpdate();
    }

    private renderConfirmResign() {
        return html`
            <style>
                .confirm-resign-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 1em;
                }
            </style>
            <h2>Resign?</h2>
            <p>Are you sure you want to resign?</p>
            <div class="confirm-resign-buttons">
                <styled-button @click="${() => {
                this.resignGame();
            }}">Yes</styled-button>
                <styled-button @click="${() => {
                this.gameState = this.displayPriorGameState!;
                this.requestUpdate();
            }}">No</styled-button>
            </div>
        `;
    }
    
    private renderDisplayInvalidMove() {
        return html`
            <style>
                .confirm-invalid-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 1em;
                }
            </style>
            <h2>Invalid Move</h2>
            <p>The move you've entered is invalid. Please try again.</p>
            <div class="confirm-invalid-buttons">
                <styled-button @click="${() => {
                    this.gameState = GameState.playerTurn;
                    this.requestUpdate();
                }}">Ok</styled-button>
            </div>
        `;
    }

    private renderDisplayAbout() {
        return TEXT_ABOUT;
    }

    private renderDisplayMore() {
        return TEXT_MORE;
    }

    private renderViewGame() {
        return html`
            <style>
                chess-game-review {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .chess-review-buttons {
                    margin-top: 10px;
                    display: flex;
                    gap: 0.5em;
                    justify-content: center;
                }
            </style>
            <chess-game-review
                .moves="${this.sessionCache.movesFen}"
                .isBlack="${this.sessionCache.playerOne === PlayerType.AI}"
            ></chess-game-review>
            <div class="chess-review-buttons">
                <styled-button class="post-game-action" @click="${(e: any) => {
                    this.onCopyMoves(e);
                }}">Copy Moves</styled-button>
                <styled-button class="post-game-action" @click="${() => {
                    this.gameState = GameState.gamePending;
                    this.requestUpdate();
                }}">Play Again</styled-button>
            </div>
        `;
    }

    private renderTimedExercise() {
        return html`
            <timed-exercise @exit="${() => {
                this.gameState = GameState.gamePending;
                this.requestUpdate();
            }}"></timed-exercise>
        `;
    }

    private onHowToClick(): void {
        console.log("[BlindChessGame] how-to clicked");
        if (!this.displayPriorGameState) {
            this.displayPriorGameState = this.gameState;
        }
        this.gameState = GameState.displayAbout;
        this.requestUpdate();
    }

    private async resignGame(): Promise<void> {
        this.gameOverState = {
            playerResigned: true,
            winner: PlayerType.AI,
            isStalemate: false
        }
        await this.endGame();
        this.soundService.play(Sounds.chessCheckmate);
    }

    private async endGame(): Promise<void> {
        this.gameState = GameState.gameOver;
        this.lastMove = "";
        await this.storageService.remove(this.saveGameKey);
        this.requestUpdate();
    }

    /**
     * Start the game as white or black
     */
    private startNewGame(): void {
        if (!this.playerSelection || this.difficulty === -1) {
            return;
        }

        let isWhite: boolean;
        switch (this.playerSelection) {
            case PlayerSelection.random:
                isWhite = Math.random() < 0.5;
                break;
            case PlayerSelection.white:
                isWhite = true;
                break;
            default:
                isWhite = false;
                break;
        }

        this.playerMoveEventEmitter = mitt<{ move: PlayerMove }>();

        console.log(`[BlindChessGame] starting game as ${isWhite ? 'white' : 'black'}`);
        this.gameState = isWhite ? GameState.playerTurn : GameState.aiTurn;

        // make the players, then reverse
        const aiPlayer = new AIPlayer(this.difficulty);
        const players = [
            aiPlayer,
            new HumanPlayer(
                this.playerMoveEventEmitter
            )
        ];

        if (isWhite) {
            players.reverse();
        }

        this.chessGame = new ChessGame(players[0], players[1]);
        this.sessionCache = {
            boardFen: this.chessGame.getFen(),
            movesFen: [],
            aiDifficulty: aiPlayer.getDifficulty(),
            playerOne: players[0].type,
            currentPlayer: players[0].type,
            gameStartTimestamp: +new Date(),
            numPlies: 0,
            numInvalidMoves: 0
        };

        this.gameOverState = {
            playerResigned: false,
            winner: null,
            isStalemate: false
        }

        this.lastMove = "";
        this.requestUpdate();
        this.enterGameLoop();
        this.soundService.play(Sounds.chessMoveValid);
    }

    /**
     * If a game exists on the local storage, try to load it
     */
    private async tryLoadGame(): Promise<boolean> {
        const game = await this.storageService.get<ChessGameSession>(this.saveGameKey);
        if (!game) {
            return false;
        }

        this.playerMoveEventEmitter = mitt<{ move: PlayerMove }>();
        // make the players, then reverse
        const players = [
            new AIPlayer(game.aiDifficulty),
            new HumanPlayer(
                this.playerMoveEventEmitter
            )
        ];

        if (game.playerOne === PlayerType.Human) {
            players.reverse();
        }

        this.gameState = game.currentPlayer === PlayerType.Human
            ? GameState.playerTurn
            : GameState.aiTurn;

        this.chessGame = new ChessGame(
            players[0],
            players[1],
            game.boardFen
        );
        this.lastMove = !game?.movesFen?.length
            ? ""
            : game.movesFen[game.movesFen.length - 1];
        this.sessionCache = game;
        this.requestUpdate();
        this.enterGameLoop();

        return true;
    }

    // TODO: add condition for player and AI resigning
    private async enterGameLoop(): Promise<void> {
        console.log(`[BlindChessGame] entering game loop`);
        while (!this.chessGame.isGameOver()) {
            const start = +new Date();
            const lastMove = await Retry<string>(
                () => this.chessGame.playNextMove(),
                async (err: any) => {
                    console.warn(`[BlindChessGame] move error`, err);
                    this.gameState = GameState.displayInvalidMove;
                    this.soundService.play(Sounds.chessMoveBad);
                    this.sessionCache.numInvalidMoves ++;

                    if (this.sessionCache.numInvalidMoves < BlindChessGame.MAX_INVALID_MOVES) {
                        await this.saveSession();
                    } else {
                        this.resignGame();
                    }
                    this.requestUpdate();
                }
            );
            const end = +new Date();
            if (end - start < 1_000) {
                await new Promise(r => setTimeout(r, 1_000 - (end - start)));
            }
            this.soundService.play(Sounds.chessMoveValid);
            this.lastMove = lastMove;

            this.toggleCurrentPlayer();
            this.sessionCache = {
                ...this.sessionCache,
                numPlies: this.sessionCache.numPlies + 1,
                boardFen: this.chessGame.getFen(),
                movesFen: [...this.sessionCache.movesFen, this.lastMove],
                currentPlayer: this.gameState === GameState.playerTurn
                    ? PlayerType.Human
                    : PlayerType.AI
            };
            await this.saveSession();
            this.requestUpdate();
        }

        // if this isn't a resignation, mark the winner
        if (!this.gameOverState) {
            // winner is the person who made the last move
            const winner = this.sessionCache.currentPlayer === PlayerType.Human
                ? PlayerType.AI
                : PlayerType.Human;

            this.gameOverState = {
                winner: this.chessGame.isStalemate() ? null : winner,
                isStalemate: this.chessGame.isStalemate(),
                playerResigned: false
            };
        }
        this.gameState = GameState.gameOver;
        this.soundService.play(Sounds.chessCheckmate);
    }

    private toggleCurrentPlayer(): void {
        this.gameState = this.gameState === GameState.playerTurn
            ? GameState.aiTurn
            : GameState.playerTurn;
    }

    private onPlayerMoveSubmit(e: CustomEvent): void {
        const move = e.detail.move;
        console.log(`[BlindChessGame] player move submitted`, move);
        this.playerMoveEventEmitter.emit('move', move);
    }

    private async saveSession(): Promise<void> {
        this.storageService.set(this.saveGameKey, this.sessionCache);
    }

    async requestUpdate(name?: PropertyKey, oldValue?: unknown, options: any = {
        transition: true
    }) {
        if (!options?.transition) {
            return super.requestUpdate(name, oldValue, options);
        }

        // Prevent additional updates during transition
        if (this.isTransitioning) {
            return;
        }

        // Apply fade-out class
        const container = this;

        this.isTransitioning = true;
        container.classList.add('fade-out');

        // Wait for fade-out animation to complete
        await new Promise(resolve => setTimeout(resolve, 150));

        // Proceed with the state update
        super.requestUpdate(name, oldValue);

        // Remove fade-out and add fade-in
        container.classList.remove('fade-out');
        container.classList.add('fade-in');

        // Remove fade-in after animation completes
        setTimeout(() => {
            container.classList.remove('fade-in');
            this.isTransitioning = false;
        }, 150);
    }

    private onCopyMoves(e: any): void {
        const target = e.target;
        const innerText = target.innerText;

        let moves = "";
        for (let i=0; i<this.sessionCache.movesFen.length; i++) {
            const move = this.sessionCache.movesFen[i];
            if (i % 2 === 0) {
                moves += `\n${i/2 + 1}.`;
            }

            moves += ` ${move}`;
        }

        moves = moves.substring(1);

        console.debug(`[BlindChessGame] copying chess game to clipboard`, moves);
        navigator.clipboard.writeText(moves)
            .then(() => {
                target.innerText = "Copied!";
                setTimeout(() => {
                    target.innerText = innerText;
                }, 2_000);
            })
            .catch(() => {
                target.innerText = "Error Copying!";
                setTimeout(() => {
                    target.innerText = innerText;
                }, 2_000);
            })
    }

    private onViewGame(): void {
        this.gameState = GameState.viewGame;
        this.requestUpdate();
    }
}