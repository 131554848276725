import { html } from "lit";
import { ModalLayover } from "../components/modal-layover";

export class ModalService {

    showModal(id: string, title: string, contentTemplate = html``) {
        this.hideModal(id);

        const modal = new ModalLayover();
        modal.id = id;
        modal.title = title;
        modal.contentTemplate = contentTemplate;

        document.body.appendChild(modal);
        modal.show();
    }

    hideModal(id: string): void {
        const existing = document.getElementById(id);
        if (existing) {
            document.body.removeChild(existing);
        }
    }
}