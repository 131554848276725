import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators';

@customElement('styled-button')
export class StyledButton extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
            user-select: none;
        }

        .button {
            font-family: var(--button-font, "Montserrat", sans-serif;); /* Default to a sans-serif if not set */
            font-size: var(--font-size, 1rem); /* Default font size */
            font-weight: bold;
            color: var(--color-override, var(--body-color, #E0E0E0)); /* Default color */
            background-color: var(--button-background-color, #2D2D2D); /* Default background color */
            border: var(--border, 1px solid var #E0E0E0); /* Default border color */
            padding: var(--padding, 10px 20px);
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
            border-radius: var(--radius, 6px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .button:hover {
            background-color: var(--button-hover-background-color, #3A3A3A); /* Hover background color */
            color: var(--background-color, #2D2D2D);
            transform: translateY(-2px);
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
        }

        .button[selected] {
            background-color: var(--button-hover-background-color, #3A3A3A); /* Hover background color */
            color: var(--background-color, #2D2D2D);
        }

        .button:active {
            background-color: var(--button-active-background-color, #4A4A4A); /* Active background color */
            transform: translateY(0); /* Reset position */
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        }

        .button:disabled {
            background-color: var(--button-disabled-background-color, #6A6A6A); /* Disabled background */
            color: var(--disabled-color, #A0A0A0); /* Disabled text color */
            border-color: var(--disabled-border-color, #6A6A6A); /* Disabled border color */
            cursor: not-allowed;
            box-shadow: none;
            transform: none;
        }
    `;

    render() {
        return html`
            <button 
                class="button" 
                ?disabled="${(this as any).disabled}" 
                ?selected="${(this as any).selected}">
                <slot></slot>
            </button>
        `;
    }

    static get properties() {
        return {
            disabled: { type: Boolean },
            selected: { type: Boolean }
        };
    }
}
