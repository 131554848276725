/**
 * Read only round robin from an input list
 */
export class RoundRobinList<T> {

    private startIndex = 0;

    constructor(private items: T[]) {}

    shiftLeft(): void {
        this.startIndex -= 1;
        if (this.startIndex < 0) {
            this.startIndex = this.items.length - 1;
        }
    }

    shiftRight(): void {
        this.startIndex += 1;
        if (this.startIndex === this.items.length) {
            this.startIndex = 0;
        }
    }

    get(index: number): T {
        if (Math.abs(index) >= this.items.length) {
            throw "Out of bounds!";
        }

        index += this.startIndex;
        if (index < 0) {
            index += this.items.length;
        } else if (index >= this.items.length) {
            index -= this.items.length;
        }

        return this.items[index];
    }

    setFirstItem(item: T, callback: (a: T, b: T) => boolean = (a, b) => a === b): void {
        const itemIndex = this.findIndex(item, callback);
        if (itemIndex === -1) {
            return;
        }

        while (!callback(item, this.get(0))) {
            this.shiftLeft();
        }
    }

    findIndex(item: T, callback: (a: T, b: T) => boolean = (a, b) => a === b): number {
        let foundIndex = -1;
        // technically, this doesn't stop searching, we can leave as is for now
        this.forEach((searchItem, index) => {
            if (callback(item, searchItem)) {
                foundIndex = index;
            }
        });

        return foundIndex;
    }

    forEach(
        callback: (item: T, index: number) => void,
        startIndex: number = 0,
        count: number = this.items.length
    ): void {
        let i=this.startIndex + startIndex;
        if (i < 0) {
            i += this.items.length;
        }

        let publicIndex = startIndex;
        let numVisited = 0;
        do {
            callback(this.items[i], publicIndex);

            // increment
            publicIndex ++;
            numVisited ++;
            if (i === this.items.length - 1) {
                i = 0;
            } else {
                i++;
            }
        } while (numVisited < count);
    }

    map<R = T>(
        callback: (item: T, index: number) => R,
        startIndex: number = 0,
        count: number = this.items.length
    ): R[] {
        const ar: R[] = [];
        this.forEach((item: T, index: number) => {
            ar.push(callback(item, index));
        }, startIndex, count);
        return ar;
    }
}