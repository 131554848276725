import { html, LitElement } from "lit";
import { customElement } from "lit/decorators";

@customElement('split-hr')
export class SplitHr extends LitElement {

    render() {
        return html`
            <style>
                .divider {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    margin: 1em;
                }

                .divider::before,
                .divider::after {
                    content: '';
                    flex: 1;
                    border-bottom: 1px solid var(--body-color);
                }

                .divider span {
                    padding: 0 10px;
                    color: var(--body-color);
                }
            </style>

            <div class="divider">
                <span><slot></slot></span>
            </div>
        `
    }

}