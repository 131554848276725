import { Square } from 'chess.js';

// Enum to define the type of player
export enum PlayerType {
    Human = 'Human',
    AI = 'AI'
}

// Common interface for players (both AI and human)
export interface Player {
    type: PlayerType;
    getMove(fen: string): Promise<PlayerMove>; // All players return a move asynchronously
}

// Common interface for moves
export interface PlayerMoveFromTo {
    from: Square;  // From position (e.g., 'e2')
    to: Square;    // To position (e.g., 'e4')
    promotion?: string;
}

export interface PlayerMovePieceTo {
    piece: string;
    to: Square
    promotion?: string;
}

export type PlayerMove = PlayerMoveFromTo | PlayerMovePieceTo | string;

export type MoveEvent = {
    move: PlayerMove; // 'move' event carries a PlayerMove object
}