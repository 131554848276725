export async function Retry<T>(
    callback: Function,
    onCatch: Function = () => {},
    numTries = Infinity
): Promise<T> {
    let response: T;

    let tryNo = numTries === Infinity
        ? -Infinity
        : 0;
    while (true) {
        try {
            response = await callback();
            break;
        } catch (err) {
            onCatch(err);
            tryNo ++;
            if (tryNo === numTries) {
                throw err;
            }
        }
    }

    return response;
}