import { StorageService } from "../models/storage-service";

// Implement the interface using LocalStorage
export class LocalStorageService implements StorageService {

    private static instance: LocalStorageService;

    constructor() {
        // TODO: this is a HACKY WAY to enforce singleton
        if (LocalStorageService.instance) {
            return LocalStorageService.instance;
        }

        LocalStorageService.instance = this;
    }

    async get<T>(key: string): Promise<T | null> {
        const item = localStorage.getItem(key);
        if (!item) {
            return Promise.resolve(null);
        } 

        return Promise.resolve(JSON.parse(item));
    }

    async set<T>(key: string, value: T): Promise<void> {
        return Promise.resolve(localStorage.setItem(key, JSON.stringify(value)));
    }

    async remove(key: string): Promise<void> {
        return Promise.resolve(localStorage.removeItem(key));
    }
}
