import { html } from "lit";
import { TimedExerciseQuestion, TimedExerciseQuestionService } from "../models/timed-exercise";

export class CellColorTimedExerciseQuestionService implements TimedExerciseQuestionService {

    getInfo(): { title: any; description: any; } {
        return {
            title: "Cell Color Exercises",
            description: "Determine the color of each given cell."
        }
    }

    getQuestion(): TimedExerciseQuestion {
        const colInd = Math.floor(Math.random() * 8);
        const randomCol = ["a", "b", "c", "d", "e", "f", "g", "h"][colInd];
        const randomRow = Math.floor(Math.random()*8)+1;

        const isWhite = colInd % 2 === randomRow % 2;

        return {
            prompt: html`
                <style>
                    .cell {
                        font-size: 90%;
                        color: #A0A0A0; /* Subtle gray */
                    }
                </style>
                What color is cell <span class="cell">${randomCol}${randomRow}</span>?
            `,
            choices: [
                {
                    text: "Light",
                    isCorrect: isWhite
                },
                {
                    text: "Dark",
                    isCorrect: !isWhite
                }
            ]
        };
    }
}