import { Chess, Move, Square } from 'chess.js';
import { Player, PlayerMove, PlayerMoveFromTo, PlayerMovePieceTo } from '../models/chess-engine-models';

export class ChessGame {
    private chess: Chess;
    private playerWhite: Player;
    private playerBlack: Player;

    constructor(playerWhite: Player, playerBlack: Player, fen?: string) {
        this.chess = fen ? new Chess(fen) : new Chess();
        this.playerWhite = playerWhite;
        this.playerBlack = playerBlack;
    }

    private getCurrentPlayer(): Player {
        return this.chess.turn() === 'w' ? this.playerWhite : this.playerBlack;
    }

    public async playNextMove(): Promise<string> {
        const currentPlayer = this.getCurrentPlayer();
        const fen = this.chess.fen();
        const move = await currentPlayer.getMove(fen);
        
        const moveObject = this.inferMoveFromNotation(move);
        if (!moveObject || !this.makeMove(moveObject)) {
            console.warn(`[ChessGame] invalid move submitted`, move);
            throw 'Invalid move';
        }

        return this.chess.history().pop()!;
    }

    // Infers the complete move object based on the piece type and destination square
    private inferMoveFromNotation(move: PlayerMove): PlayerMove | null {
        if (typeof (move as PlayerMovePieceTo).piece === 'undefined') {
            return move;
        }

        const legalMoves = this.chess.moves({ verbose: true }) as Move[];
        
        const mT = (move as PlayerMovePieceTo);
        // Filter moves by destination and piece type
        const possibleMoves = legalMoves.filter(m => {
            return m.to === mT.to
                && (m.piece === mT.piece.toLowerCase() || m.from === mT.piece.toLowerCase())
                && (!mT.promotion || mT.promotion.toLowerCase() === m.promotion?.toLowerCase())
        });
        console.log(legalMoves, possibleMoves);

        if (possibleMoves.length === 1) {
            // Single possible move, return the 'from' and 'to'
            const inferred = possibleMoves[0];
            console.log(`[ChessGame] inferred move from notation`, { move, inferred });
            return inferred;
        } else if (possibleMoves.length > 1) {
            // Ambiguity: multiple pieces can move to the same square
            // Additional logic can be added here to disambiguate based on the board context
            // For now, we return the first possible move to keep it simple
            const inferred = possibleMoves[0];
            console.log(`[ChessGame] inferred move from notation`, { move, inferred });
            return inferred;
        }

        return null;
    }

    private makeMove(move: PlayerMove): boolean {
        // TODO: need to handle promotion
        const result: Move | null = this.chess.move(move as any);
        return result !== null;
    }

    public isGameOver(): boolean {
        return this.chess.isGameOver();
    }

    public isStalemate(): boolean {
        return this.chess.isStalemate();
    }

    public getPgn(): string {
        return this.chess.pgn();
    }

    public getFen(): string {
        return this.chess.fen();
    }
}
