import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Chess } from 'chess.js';
import { SoundService } from '../sound-service/sound-service';
import { Sounds } from '../sound-service/sounds';

@customElement('chess-game-review')
export class ChessGameReview extends LitElement {
    @property({ type: Array }) moves: string[] = [];
    @property({ type: Boolean }) isBlack: boolean = false;

    private chess = new Chess();
    private currentMoveIndex = 0;
    private board: any;
    private soundService = new SoundService();

    static styles = css`
        .board-container {
            width: min(50dvw, 60dvh);
        }

        .move-controls {
            margin-top: 10px;
            display: flex;
            gap: 0.5em;
        }

        @media screen and (max-width: 1500px) {
            .board-container {
                width: min(70dvw, 70dvh);
            }
        }
    `;

    createRenderRoot() {
        return this; // Use light DOM to ensure ChessBoard.js can access the element.
    }

    connectedCallback(): void {
        super.connectedCallback();
        window.addEventListener('resize', () => this.board.resize());
        window.addEventListener('keydown', this.handleKeydown.bind(this)); // Add keydown listener
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener('resize', () => this.board.resize());
        window.removeEventListener('keydown', this.handleKeydown.bind(this)); // Remove keydown listener
    }

    firstUpdated() {
        // Initialize the ChessBoard
        this.board = (window as any).Chessboard(this.querySelector('#board'), {
            position: 'start',
            orientation: this.isBlack ? 'black' : 'white',
        });
    }

    private updateBoard() {
        const fen = this.chess.fen();
        this.board.position(fen);
    }

    private moveForward() {
        if (this.currentMoveIndex < this.moves.length) {
            this.chess.move(this.moves[this.currentMoveIndex]);
            this.currentMoveIndex++;
            this.updateBoard();
            this.soundService.play(Sounds.chessMoveValid);

            if (this.chess.isGameOver()) {
                this.soundService.play(Sounds.chessCheckmate);
            }
        }
    }

    private moveBackward() {
        if (this.currentMoveIndex > 0) {
            this.chess.undo();
            this.currentMoveIndex--;
            this.soundService.play(Sounds.chessMoveValid);
            this.updateBoard();
        }
    }

    private handleKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowLeft') {
            this.moveBackward();
        } else if (event.key === 'ArrowRight') {
            this.moveForward();
        }
    }

    render() {
        return html`
      <style>
        ${ChessGameReview.styles}
      </style>
      <div class="board-container">
        <div id="board"></div>
      </div>

      <div class="move-controls">
        <styled-button @click="${() => this.moveBackward()}">←</styled-button>
        <styled-button @click="${() => this.moveForward()}">→</styled-button>
      </div>
    `;
    }
}
