import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './chess-position-select'; // Ensure this path matches your project structure
import { ClientService } from '../client-service/client-service';
import { MoveInputType } from '../models/client-config';
import { PlayerMove } from '../models/chess-engine-models';
import { Square } from 'chess.js';

@customElement('player-move-input-form')
class PlayerMoveInputForm extends LitElement {

    private clientService = new ClientService();

    // Properties to hold the 'from' and 'to' positions
    @property({ type: String }) fromPosition: string = 'p';
    @property({ type: String }) toPosition: string = 'd4';
    @property({ type: String }) actionButtons: { text: string; code: string; }[] = [];
    @property({ type: Boolean, reflect: true }) horizontal: boolean = false;
    @property({ type: Boolean, reflect: true }) isWhite = false;

    // State to track the current step (1 = From, 2 = To)
    @state() private currentStep: number = 1;

    // show this if piece is pawn and col is 1/8 (black / white), or if piece is board and to col is 1/8 (black / white)
    private promotionView: '' | 'promotion' | 'promotion-blind' = '';
    private promotionPiece = '';

    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.updatePromotionView();
    }

    // Handler for when the 'from' position changes
    private onFromPositionChange(e: CustomEvent) {
        this.fromPosition = e.detail.piece;
        this.updatePromotionView();
        this.requestUpdate();
    }

    // Handler for when the 'to' position changes (first manual change marks the click)
    private onToPositionChange(e: CustomEvent) {
        this.toPosition = e.detail.position;

        this.updatePromotionView();
        this.requestUpdate();
    }

    private onPromotionChange(e: CustomEvent) {
        this.promotionPiece = e.detail.piece;

        this.updatePromotionView();
        this.requestUpdate();
    }

    // Handle the submit button click and emit the move event
    private onSubmit() {

        let move: PlayerMove;
        if (typeof this.fromPosition === 'string') {
            move = { piece: this.fromPosition, to: (this.toPosition as Square) };
        } else {
            move = { from: this.fromPosition, to: (this.toPosition as Square) };
        }

        if (this.promotionView) {
            move.promotion = this.promotionPiece;
        }

        console.debug(`[PlayerMoveInputForm] dispatching move`, move);
        this.dispatchEvent(new CustomEvent('move-submit', {
            detail: { move },
            bubbles: true,
            composed: true
        }));
    }

    private onActionButtonClick(code: string) {
        this.dispatchEvent(new CustomEvent(`action-button-click-${code}`, {}));
    }

    // Method to navigate between steps
    private nextStep() {
        this.currentStep = 2;
    }

    private previousStep() {
        this.currentStep = 1;
    }

    private updatePromotionView(): void {
        const toCol = this.toPosition[1];

        if (this.isWhite && toCol !== '8' || !this.isWhite && toCol !== '1') {
            this.promotionView = '';
            return;
        }

        if (this.fromPosition !== 'p' && this.fromPosition.length < 2) {
            this.promotionView = '';
            return;
        }

        this.promotionView = this.fromPosition === 'p'
            ? 'promotion'
            : 'promotion-blind';
    }

    /**
     * Depending on the client, render both inputs at once, or just one
     */
    render() {
        switch (this.clientService.getConfig().body.moveInputType) {
            case MoveInputType.multiPage:
                return this.renderMultiPage();
            default:
                return this.renderSinglePage();
        }
    }

    // Render the two chess-position-select components and a submit button for single-page
    private renderSinglePage() {
        const buttons = this.actionButtons.map(({ text, code }) => html`
            <styled-button @click="${() => this.onActionButtonClick(code)}">${text}</styled-button>
        `);
        const submitButton = html`<styled-button class="submit-button" @click="${this.onSubmit}">Submit!</styled-button>`;

        return html`
            <style>${PlayerMoveInputForm.stylesSimultaneous}</style>
            <div class="chess-move-input-form-container">
                <div class="input-container">
                    <!-- From Position -->
                    <div class="input">
                        <chess-move-type-select
                            @move-change="${this.onFromPositionChange}"
                            .selectedPosition="${this.fromPosition}"
                            .horizontal="${this.horizontal}"
                        >
                        </chess-move-type-select>
                    </div>

                    <!-- To Position -->
                    <div class="input">
                        <chess-position-select
                            @position-change="${this.onToPositionChange}"
                            .selectedPosition="${this.toPosition}"
                            .horizontal="${this.horizontal}"
                        >
                        </chess-position-select>
                    </div>

                    ${this.promotionView === '' ? '' : html`
                        <div class="input">
                            <chess-move-type-select
                                @move-change="${this.onPromotionChange}"
                                .mode="${this.promotionView}"
                            >
                            </chess-move-type-select>
                        </div>
                    `}
                </div>
                <div class="navigation-buttons">
                    ${submitButton}
                </div>
                <div class="navigation-buttons small-nav">
                    ${buttons}
                </div>
            </div>
        `;
    }

    // Render the multi-page input form
    // TODO: this needs to be updated with recent changes, such as promotion
    private renderMultiPage() {
        const buttons = this.actionButtons.map(({ text, code }) => html`
            <styled-button @click="${() => this.onActionButtonClick(code)}">${text}</styled-button>
        `);
        const nextButtons = this.currentStep === 1
            ? [html`<styled-button @click="${this.nextStep}">Next</styled-button>`]
            : [
                html`<styled-button class="secondary-button" @click="${this.previousStep}">Previous</styled-button>`,
                html`<styled-button class="secondary-button" @click="${this.onSubmit}">Submit!</styled-button>`
            ];

        return html`
            <style>${PlayerMoveInputForm.stylesMultiPage}</style>
            <div class="chess-move-input-form-container">
                ${this.currentStep === 1 ? html`
                    <div class="input-container">
                        <div class="input">
                            <label>From: </label>
                            <chess-position-select
                                @position-change="${this.onFromPositionChange}"
                                .selectedPosition="${this.fromPosition}"
                                .horizontal="${this.horizontal}"
                            >
                            </chess-position-select>
                        </div>
                    </div>
                    <div class="navigation-buttons">
                        ${nextButtons}
                    </div>
                    <div class="navigation-buttons small-nav">
                        ${buttons}
                    </div>
                ` : html`
                    <div class="input-container">
                        <div class="input">
                            <label>To: </label>
                            <chess-position-select
                                @position-change="${this.onToPositionChange}"
                                .selectedPosition="${this.toPosition}"
                                .horizontal="${this.horizontal}"
                            >
                            </chess-position-select>
                        </div>
                    </div>
                    <div class="navigation-buttons">
                        ${nextButtons}
                    </div>
                    <div class="navigation-buttons small-nav">
                        ${buttons}
                    </div>
                `}
            </div>
        `;
    }

    static stylesSimultaneous = css`
        .chess-move-input-form-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            font-size: 120%;
        }

        .input-container {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }

        .navigation-buttons {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 1rem;
            gap: 1rem;
        }

        .small-nav > styled-button {
             --font-size: calc(var(--item-size) * 0.5);
        }

        .submit-button {
            color: #E0E0E0;
            font-weight: 600;
        }

        .submit-button:hover {
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
        }

        .secondary-button {
            font-weight: 400; /* Lighter weight for Help and Resign */
            padding: 8px 16px;
            border-radius: 4px;
        }
    `;

    static stylesMultiPage = css`
        .chess-move-input-form-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .input-container {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }

        .navigation-buttons {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 1rem;
            gap: 1rem;
        }

        chess-position-select {
            --font-size: 1.2em;
        }

        .small-nav > styled-button {
            --font-size: calc(var(--item-size) * 0.5);
        }

        .submit-button {
            color: #E0E0E0;
            font-weight: 600;
        }

        .submit-button:hover {
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
        }
    `;
}
