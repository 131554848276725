import { LitElement, html, css } from 'lit';
import { customElement, eventOptions, property } from 'lit/decorators.js';
import { ClientService } from '../client-service/client-service';

@customElement('modal-layover')
export class ModalLayover extends LitElement {

    private clientService = new ClientService();

    static styles = css`
        :host {}

        .layover-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: var(--modal-background-color, rgba(0, 0, 0, 0.5));
            z-index: 1000;
        }

        .layover-container-full {
            background: rgba(var(--background-color-components), 0.9);
        }

        .layover {
            position: relative;
            width: 50%;
            max-width: min(var(--max-width, 600px), 90vw);
            max-height: 70vh;

            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            background-color: var(--background-color);
            border: 2px solid var(--body-color);
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            padding-top: 40px; 

            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);
        }

        .layover-full {
            transform: translate(-50%, -70%);
            border: 0;
            background-color: unset;
            box-shadow: none;
            padding-top: 0;
        }

        .layover-animate {
            animation: slideIn 0.2s ease-out forwards;
        }

        @keyframes slideIn {
            from {
                transform: translate(-50%, 150%); /* Start from below the view */
            }
            to {
                transform: translate(-50%, -50%);
            }
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 1.2em;
            cursor: pointer;
            color: var(--body-color);
            border: 1px solid var(--body-color);
            border-radius: 10px;
        }

        .close-button:hover {
            background-color: var(--button-hover-background-color) !important;
        }

        .title {
            /*font-size: 1.5em;*/
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 20px;
        }

        .layover-container-closed {
            display: none;
        }

        @media screen and (max-width: 800px) {
            .layover {
                width: 80%;
            }
        }
    `;

    private escapeEvent: Event | null = null;

    @property({ attribute: false }) contentTemplate = html``;
    @property({ type: String }) title = '';
    @property({ type: Boolean, reflect: true }) open = false;

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    @eventOptions({ capture: true })
    handleClose() {
        this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true }));
    }

    render() {
        console.log("[ModalLayover] rendering");
        let mainClass = "layover";
        let containerClass = "layover-container";
        let showX = true;
        if (this.clientService.getConfig().modalBox.coverEntireScreen) {
            mainClass += " layover-full";
            containerClass += " layover-container-full";
            showX = false;
        }

        return html`
            <style>
                ${ModalLayover.styles}
            </style>
            <div class="${containerClass} ${this.open ? "" : "layover-container-closed"}" @click="${(e: MouseEvent) => this._onClickOutside(e)}">
                <div class="${mainClass} ${this.open ? "layover-animate" : ""}" @click="${(e: MouseEvent) => this._onModalClick(e)}">
                    ${showX ? html`<button class="close-button" @click="${this.hide}">&#10005;</button>` : ""}
                    <h2 class="title">${this.title}</h2>
                    ${this.contentTemplate}
                </div>
            </div>
        `;
    }

    show() {
        this.open = true;
        this.requestUpdate(); // Force re-render to apply the animation class
        this.dispatchEvent(new CustomEvent('modal-show'));

        this.escapeEvent = document.addEventListener('keydown', (e: any) => {
            if (e.key === 'Escape') {
                this.hide();
            }
        }) as any;
    }

    hide() {
        this.open = false;
        this.handleClose();
        this.dispatchEvent(new CustomEvent('modal-hide'));
        document.removeEventListener('keydown', this.escapeEvent as any);
        this.escapeEvent = null;
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('contentTemplate')) {
            this.requestUpdate();
        }
    }

    private _onClickOutside(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.hide();
        }
    }

    private _onModalClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
