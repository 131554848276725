import { html } from "lit";
import { TimedExerciseQuestion, TimedExerciseQuestionService } from "../models/timed-exercise";

export class RowColDistanceTimedExerciseQuestionService implements TimedExerciseQuestionService {
    
    getInfo(): { title: any; description: any; } {
        return {
            title: 'Row-Col Distance Exercises',
            description: `Given two rows or columns, determine the number of rows or columns in-between them.`
        }
    }
    
    getQuestion(): TimedExerciseQuestion {
        const cols = ["a", "b", "c", "d", "e", "f", "g", "h"];

        const ab = [Math.floor(Math.random()*8), Math.floor(Math.random()*8)];
        while (ab[1] === ab[0]) {
            ab[1] = Math.floor(Math.random()*8);
        }

        if (ab[0] > ab[1]) {
            const tmp = ab[0];
            ab[0] = ab[1];
            ab[1] = tmp;
        }

        const isRow = Math.random() < 0.5;
        const numBetween = ab[1] - ab[0] - 1;

        return {
            prompt: html`
                <style>
                    .rowcol {
                        font-size: 90%;
                        font-weight: bold;
                        color: #A0A0A0; /* Subtle gray */
                    }
                </style>
                How many ${isRow ? 'rows' : 'columns'} are in-between <span class="rowcol">${isRow ? ab[0]+1 : cols[ab[0]]}</span> and <span class="rowcol">${isRow ? ab[1]+1 : cols[ab[1]]}</span>?`,
            choices: new Array(7).fill(null).map((_, ind) => ({
                text: `${ind}`,
                isCorrect: ind === numBetween
            }))
        };
    }

}