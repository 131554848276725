import { Player, PlayerMove, PlayerType } from '../models/chess-engine-models';
import { Square } from 'chess.js'; // Square type for chess coordinates

export class AIPlayer implements Player {
    type: PlayerType = PlayerType.AI;
    private stockfishWorker: Worker;

    constructor(private difficulty: number = 10) {
        // Reference the locally downloaded stockfish.js file for the Web Worker
        this.stockfishWorker = new Worker(new URL('./stockfish.js', import.meta.url), { type: 'module' });

        // Set Stockfish difficulty level
        this.stockfishWorker.postMessage(`setoption name Skill Level value ${difficulty}`);
    }

    getDifficulty(): number {
        return this.difficulty;
    }

    // Get the move from the AI player using Stockfish
    public async getMove(fen: string): Promise<PlayerMove> {
        return new Promise((resolve) => {
            const onMoveReceived = (event: MessageEvent) => {
                const data = event.data;

                if (typeof data === 'string' && data.startsWith('bestmove')) {
                    const bestMove = data.split(' ')[1];
                    const from = bestMove.substring(0, 2) as Square;
                    const to = bestMove.substring(2, 4) as Square;
                    this.stockfishWorker.removeEventListener('message', onMoveReceived);

                    resolve({ from, to });
                }
            };

            this.stockfishWorker.addEventListener("message", onMoveReceived);

            // Send the current board position (in FEN) to Stockfish
            this.stockfishWorker.postMessage(`position fen ${fen}`);
            this.stockfishWorker.postMessage(`go depth ${this.difficulty}`); // Adjust depth for AI difficulty
        });
    }
}