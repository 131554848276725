import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './scroll-selector';

@customElement('chess-position-select')
class ChessPositionSelect extends LitElement {

    private static COLUMNS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
    private static ROWS = ['1', '2', '3', '4', '5', '6', '7', '8'];
    @property({ type: Boolean, reflect: true }) horizontal: boolean = false;

    @property({ type: String }) selectedPosition: string = 'a1'; // New property for external input

    private selectedLetter: string = 'd';
    private selectedNumber: string = '4';

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        // If the 'selectedPosition' has changed, update the internal state accordingly
        if (changedProperties.has('selectedPosition') && changedProperties.get('selectedPosition') !== this.selectedPosition) {
            if (!this.selectedPosition) {
                return;
            }
            
            const [letter, number] = this.selectedPosition.split('');
            this.selectedLetter = letter;
            this.selectedNumber = number;
            this.requestUpdate(); // Update the UI after state change
        }
    }

    private onLetterChange(e: CustomEvent) {
        this.selectedLetter = e.detail.selectedItem;
        this.emitPosition();
    }

    private onNumberChange(e: CustomEvent) {
        this.selectedNumber = e.detail.selectedItem;
        this.emitPosition();
    }

    private emitPosition() {
        const position = `${this.selectedLetter}${this.selectedNumber}`;
        this.dispatchEvent(new CustomEvent('position-change', {
            detail: { position },
            bubbles: true,
            composed: true
        }));
    }

    render() {
        return html`
            <style>
                ${ChessPositionSelect.styles}
            </style>
            <div class="position-selector ${this.horizontal ? "sel-row" : ""}">
                <scroll-selector
                    .items="${ChessPositionSelect.COLUMNS}"
                    .selectedItem="${this.selectedLetter}"
                    @selection-change="${this.onLetterChange}"
                    .horizontal="${this.horizontal}"    
                >
                </scroll-selector>
                <scroll-selector
                    .items="${ChessPositionSelect.ROWS}"
                    .selectedItem="${this.selectedNumber}"
                    @selection-change="${this.onNumberChange}"
                    .horizontal="${this.horizontal}"
                >
                </scroll-selector>
            </div>
        `;
    }

    static styles = css`
        .position-selector {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 0.5em; /* Reduced space between selectors */
            padding: 0.5em; /* Minimal padding around the whole selector */
        }

        .sel-row {
            flex-direction: column;
        }
    `;
}
