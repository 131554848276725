import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { RoundRobinList } from '../round-robin-list/round-robin-list';

@customElement('scroll-selector')
class ScrollSelector extends LitElement {

    @property({ type: Array }) items: string[] | {template: any; value: any}[] = [];
    @property({ type: String }) selectedItem: string | {template: any; value: any} = '';
    @property({ type: Boolean, reflect: true }) horizontal: boolean = false;

    private roundRobinItems!: RoundRobinList<any>;

    createRenderRoot() {
        return this; // Renders into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('items') && changedProperties.get('items') !== this.items) {
            this.roundRobinItems = new RoundRobinList<any>(this.items);
            this.roundRobinItems.setFirstItem(this.selectedItem);
            this.requestUpdate();
        }

        if (changedProperties.has('selectedItem') && changedProperties.get('selectedItem') !== this.selectedItem) {
            this.roundRobinItems.setFirstItem(this.selectedItem);
            this.requestUpdate();
        }
    }

    render() {
        if (!this.roundRobinItems) {
            return;
        }

        const prevChar = this.horizontal ? "◄" : "▲";
        const nextChar = this.horizontal ? "►" : "▼";
        const styles = this.horizontal ? ScrollSelector.horizontalStyles : ScrollSelector.verticalStyles;

        return html`
            <style>${styles}</style>
            <div class="scroll-wrapper">
                <styled-button class="scroll-arrow" @click="${() => this.onUpClick()}">${prevChar}</styled-button>
                <div class="scroll-container">
                    ${this.roundRobinItems.map((item, index) => {
                        let display = item;
                        if (typeof item !== 'string') {
                            display = item.template;
                        }

                        return html`<div class="scroll-item ${index === 0 ? 'selected' : ''}">${display}</div>`;
                    }, -1, 3)}
                </div>
                <styled-button class="scroll-arrow" @click="${() => this.onDownClick()}">${nextChar}</styled-button>
            </div>
        `;
    }

    private onUpClick() {
        this.roundRobinItems.shiftLeft();
        this.selectedItem = this.roundRobinItems.get(0);
        this.emitSelection();
        this.requestUpdate();
    }

    private onDownClick() {
        this.roundRobinItems.shiftRight();
        this.selectedItem = this.roundRobinItems.get(0);
        this.emitSelection();
        this.requestUpdate();
    }

    private emitSelection() {
        let selectedItem = this.selectedItem;
        if (typeof selectedItem !== 'string') {
            selectedItem = (this.selectedItem as any).value;
        }

        this.dispatchEvent(new CustomEvent('selection-change', {
            detail: { selectedItem },
            bubbles: true,
            composed: true
        }));
    }

    static verticalStyles = css`
        .scroll-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            /* width: calc(var(--item-size) * 3); */
        }

        .selected {
            font-weight: bold;
            color: #FFD700; /* Soft gold for active items */
        }

        .scroll-container {
            height: calc(var(--item-size) * 7);
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            cursor: grab;
            scroll-behavior: smooth;
        }

        .scroll-container:active {
            cursor: grabbing;
        }

        .scroll-container::-webkit-scrollbar {
            display: none;
        }

        .scroll-item {
            height: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
        }

        .scroll-item img {
            height: 60%;
        }

        styled-button {
            user-select: none;
            cursor: pointer;
            background: none;
            border: none;
        }
    `;

    static horizontalStyles = css`
        .scroll-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: calc(var(--item-size) * 3);
        }

        .selected {
            font-weight: bold;
            color: #FFD700; /* Soft gold for active items */
        }

        .scroll-container {
            width: calc(var(--item-size) * 7);
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            cursor: grab;
            scroll-behavior: smooth;
            display: flex;
        }

        .scroll-container:active {
            cursor: grabbing;
        }

        .scroll-container::-webkit-scrollbar {
            display: none;
        }

        .scroll-item {
            width: 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
        }

        .scroll-item img {
            height: 60%;
        }

        .buffer-item {
            visibility: hidden;
            width: var(--item-size); /* Use global item size */
        }

        styled-button {
            user-select: none;
            cursor: pointer;
            background: none;
            border: none;
        }

        .scroll-arrow {

        }
    `;
}
