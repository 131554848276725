export enum Sounds {
    chessMoveValid = "chess-move-valid",
    chessCheckmate = "chess-checkmate",
    chessMoveBad = "chess-move-bad"
}

export const SOUND_PATHS = {
    [Sounds.chessMoveValid]: "./chess-move.mp3",
    [Sounds.chessMoveBad]: "./chess-move-bad.mp3",
    [Sounds.chessCheckmate]: "./checkmate.mp3"
}