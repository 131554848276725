import { AxisOrientation, ClientConfig, MoveInputType } from "../models/client-config";
import { WEB_BROWSER_CONFIG } from "./web-browser-config";

export const LOCALHOST_CONFIG: ClientConfig = {
    body: {
        cssClasses: [],
        moveInputType: MoveInputType.singlePage
    },
    training: {
        enabled: true,
        timedEnabled: true,
        puzzlesEnabled: true
    },
    chessInput: {
        orientation: AxisOrientation.vertical
    },
    modalBox: {
        coverEntireScreen: false
    }
};