import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './scroll-selector';

@customElement('chess-move-type-select')
class ChessMoveTypeSelect extends LitElement {

    private static POS_VALUE = "xy";
    private static ENTRIES = [
        { template: html`<img src="/none.png" />`, value: '', modes: ['promotion-blind'] },
        { template: html`<img src="/pawn.png" />`, value: 'p', modes: ['position'] },
        { template: html`<img src="/knight.png" />`, value: 'N', modes: ['position', 'promotion', 'promotion-blind'] },
        { template: html`<img src="/bishop.png" />`, value: 'B', modes: ['position', 'promotion', 'promotion-blind'] },
        { template: html`<img src="/rook.png" />`, value: 'R', modes: ['position', 'promotion', 'promotion-blind'] },
        { template: html`<img src="/queen.png" />`, value: 'Q', modes: ['position', 'promotion', 'promotion-blind'] },
        { template: html`<img src="/king.png" />`, value: 'K', modes: ['position'] },
        { template: html`<img src="/board.png" />`, value: ChessMoveTypeSelect.POS_VALUE, modes: ['position'] },
    ];
    @property({ type: Boolean, reflect: true }) horizontal: boolean = false;
    @property({ type: String, reflect: true }) mode: 'position' | 'promotion' | 'promotion-blind' = 'position';
    @property({ type: String }) selectedPiece: any = ChessMoveTypeSelect.ENTRIES[3].value; // New property for external input
    private _selectedPosition = "d4";

    private items: any[] = [];

    private set selectedPosition(value: string) {
        if (value.length === 1) {
            this.selectedPiece = value;
        } else {
            this._selectedPosition = value;
        }
    }

    private get selectedPosition() {
        return this._selectedPosition;
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.determineItems();
    }

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        // If the 'selectedPosition' has changed, update the internal state accordingly
        /*if (changedProperties.has('selectedPiece') && changedProperties.get('selectedPiece') !== this.selectedPiece) {
            this.requestUpdate(); // Update the UI after state change
        }*/
    }

    private onMoveTypeChange(e: CustomEvent) {
        this.selectedPiece = e.detail.selectedItem;

        if (this.selectedPiece == ChessMoveTypeSelect.POS_VALUE) {
            this.requestUpdate();
            this.emitPosition();
        } else {
            this.emitPiece();
        }
    }

    private emitPiece() {
        this.dispatchEvent(new CustomEvent('move-change', {
            detail: { piece: this.selectedPiece },
            bubbles: true,
            composed: true
        }));
    }

    render() {
        const elements = [];

        elements.push(html`
            <div class="position-selector ${this.horizontal ? "sel-row" : ""}">
                <scroll-selector
                    .items="${this.items}"
                    .selectedItem="${this.selectedPiece}"
                    @selection-change="${(e: any) => this.onMoveTypeChange(e)}"
                    .horizontal="${this.horizontal}"    
                >
                </scroll-selector>
            </div>    
        `);
        if (this.selectedPiece === ChessMoveTypeSelect.POS_VALUE) {
            console.log(`[ChessMoveTypeSelect] rendering additional position select`);
            // not either or, use both if p is the current option
            elements.push(html`
                <chess-position-select
                    @position-change="${(e: any) => this.onPositionChange(e)}"
                    .horizontal="${this.horizontal}"
                    .selectedPosition="${this.selectedPosition}"
                >
                </chess-position-select>
            `);
        }


        return html`
            <style>
                ${ChessMoveTypeSelect.styles}
            </style>
            <div style="display: flex; flex-direction:${this.horizontal ? 'column' : 'row'}">
                ${elements}
            </div>
        `;
    }

    private onPositionChange(e: any) {
        this.selectedPosition = e.detail.position;
        this.emitPosition();
    }

    private emitPosition(): void {
        this.dispatchEvent(new CustomEvent('move-change', {
            detail: { piece: this.selectedPosition },
            bubbles: true,
            composed: true
        }));
    }

    private determineItems(): void {
        this.items = ChessMoveTypeSelect.ENTRIES.filter(entry => entry.modes.includes(this.mode));
    }

    static styles = css`
        .position-selector {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 0.5em; /* Reduced space between selectors */
            padding: 0.5em; /* Minimal padding around the whole selector */
        }

        .sel-row {
            flex-direction: column;
        }
    `;
}
