import { AxisOrientation, ClientConfig, MoveInputType } from "../models/client-config";

export const ANDROID_APP_CONFIG: ClientConfig = {
    body: {
        cssClasses: [],
        moveInputType: MoveInputType.singlePage
    },
    training: {
        enabled: false,
        timedEnabled: false,
        puzzlesEnabled: false
    },
    chessInput: {
        orientation: AxisOrientation.vertical
    },
    modalBox: {
        coverEntireScreen: false
    }
};