import { html } from "lit";

export const TEXT_MORE = html`
<style>
    #more {
        text-align: left;
    }

    #tagline {
        text-align: center;
    }

    #hexakai-img {
        width: 200px;
        aspect-ratio: 0.62;
        float: left;

        margin-right: 10px;
        margin-bottom: 10px;
    }

    #author-img {
        width: 160px;
        aspect-ratio: 0.85;
        float: left;

        margin-right: 10px;
        margin-bottom: 10px;
    }

    more-section {
        display: block;
        padding-bottom: 2em;
        border-bottom: 1px solid var(--body-color);
    }

    more-section::after {
        display: block;
        content: '';
        clear: both;
    }

    h1, h2 {
        text-align: center;
    }
</style>

<div id="more">
    <more-section>
        <h1>More</h1>
        <p id="tagline">
            This page showcases my other projects and initiatives.
        </p>
    </more-section>
    <more-section>
        <h2>Hexakai</h2>
        <a href="https://hexakai.com" target="_blank">
            <img src="./hexakai.png" id="hexakai-img" />
        </a>
        <p>
            <a href="https://hexakai.com" target="_blank">Hexakai</a>, a Sudoku-inspired game of Hexagons,
            is played on a hexagonal diamond-shaped grid of cells. Instead of a single column, we have two 
            distinct diagonals, leading to highly interesting strategies and gameplay. Boards range over a 
            variety of sizes and difficulties and advanced options can be used to generate more unique experiences.
        </p>
        <p>
            The game features daily puzzles that range over seven board sizes and four difficulty levels.
            A new puzzle is published every day!
        </p>
        <p>
            The game is free on <a href="https://hexakai.com" target="_blank">hexakai.com</a> and is also available
            as <a href="https://play.google.com/store/apps/details?id=com.hexakai.andrioid.app" target="_blank">Android</a>
            and <a href="https://apps.apple.com/us/app/hexakai/id6504585426" target="_blank">iOS</a> apps.
        </p>
    </more-section>
    <more-section style="border-bottom: 0px">
        <h2>About the Author</h2>
        <img src="./author.png" id="author-img" />
        <p>
            <a href="https://brandon-quinn.com" target="_blank">Brandon Quinn</a> is a
            writer, musician, business owner, and software engineer with
            nearly a decade’s experience in each field. He graduated from Rowan University with bachelor’s degrees
            in
            computer science and saxophone performance.
        </p>
        <p>
            Since then, he has launched businesses related to music and software engineering and has created and
            published a unique saxophone technique that doubles the number of notes it can produce, as well as
            hundreds
            of articles on software engineering and architecture topics. He is currently a software engineer at
            Amazon
            in Manhattan and is a writer focusing on topics of critical thinking, rationality, and logic.
        </p>
    </more-section>
</div>
`;